import React from "react";
import { BiLoaderAlt } from "react-icons/bi";

const CommonLoader = () => {
  return (
    <div className="absolute z-50 flex h-screen w-full items-center justify-center">
      <BiLoaderAlt className="size-10 animate-spin text-blue-146EF6" />
    </div>
  );
};

export { CommonLoader };
