/* eslint-disable tailwindcss/classnames-order */
import React from "react";
import FaqComponent from "./faqComponent";

function Faq() {
  return (
    <div className="flex flex-1 ml-285px items-center justify-between flex-col overflow-y-auto rounded-tl-3xl bg-grey-f7f7f7 px-5 pt-18 gap-5">
      <FaqComponent />
      <div className="w-full flex justify-end pb-15 pr-11 font-Inter font-normal text-ft16-19.36 text-grey-757575">
        LegalHero Helpline: 888-888-8888
      </div>
    </div>
  );
}

export default Faq;
