/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DatePickerComponent = ({
  selectedDate = null,
  setSelectedDate,
  type,
  minDate = new Date(1900, 0, 1),
  maxDate = new Date()
}) => {
  const [inputValue, setInputValue] = useState(
    selectedDate ? selectedDate.toLocaleDateString("en-US") : ""
  );

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setInputValue(date ? date.toLocaleDateString("en-US") : "");
  };

  const handleInputChange = (event) => {
    const { value } = event.target;

    // Allow only digits and dashes
    const sanitizedValue = value.replace(/[^0-9-]/g, "");

    // Update the input value only if it matches the sanitized value
    if (sanitizedValue === value) {
      setInputValue(sanitizedValue);
    }
  };

  const handleKeyDown = (event) => {
    // Allow only digits, dashes, and control keys like Backspace, Tab, etc.
    const allowedKeys = [
      "Backspace",
      "Tab",
      "ArrowLeft",
      "ArrowRight",
      "Delete",
      "Home",
      "End",
      "-"
    ];

    if (!/^[0-9]$/.test(event.key) && !allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  };

  const handlePaste = (event) => {
    const paste = (event.clipboardData || window.clipboardData).getData("text");
    if (/[^0-9-]/.test(paste)) {
      event.preventDefault();
    }
  };

  return (
    <DatePicker
      selected={selectedDate}
      onChange={handleDateChange}
      customInput={
        <input
          placeholder="MM-DD-YYYY"
          type="text"
          value={inputValue}
          pattern="^[0-9\-]*$"
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          onPaste={handlePaste}
          className="mt-0.5 h-12 w-full rounded-lg border border-grey-E5E5E5 px-3 text-black-15 outline-none placeholder:text-ft13-15 placeholder:text-grey-BDBDBD"
        />
      }
      showMonthDropdown
      showYearDropdown
      dateFormat="MM-dd-yyyy"
      placeholderText="MM-DD-YYYY"
      minDate={minDate}
      maxDate={maxDate}
      yearDropdownItemNumber={101}
      onKeyDown={(e) => {
        const allowedKeys = [
          "Backspace",
          "ArrowLeft",
          "ArrowRight",
          "Delete",
          "Tab",
          "Enter" // Optional: Include if you want to allow form submissions
        ];

        // Allow digits (0-9) and hyphen (-)
        if (
          (e.key >= "0" && e.key <= "9") ||
          e.key === "-" ||
          allowedKeys.includes(e.key)
        ) {
          // Allow the keypress
          return;
        } else {
          // Prevent the keypress
          e.preventDefault();
        }
      }}
    />
  );
};

export default DatePickerComponent;
