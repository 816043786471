/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
/* eslint-disable tailwindcss/classnames-order */
import React, { useEffect, useState } from "react";
import HistoryComponent from "./historyComponent";
import { useLocation, useNavigate } from "react-router-dom";
import {
  failureMessageConstant,
  historyDummyIds,
  keyConstants
} from "../../constants";
import Utils from "../../utility";
import AdminService from "../../services/adminService";
const { ASSISMENT_DETAIL, INJURY_TYPE, SURVEY_QUESTIONS, USER } = keyConstants;

function History() {
  const location = useLocation();
  const navigate = useNavigate();
  const [currentAssessment, setCurrentAssessment] = useState(0);
  const [assismentDetails, setAssismentDetails] = useState([]);
  const [userDetailData, setUserDetailData] = useState([]);
  const user = Utils.localStorageGetItem(USER);
  const [loader, setLoader] = useState("");

  useEffect(() => {
    const userDetailData = async () => {
      await userDetail();
    };
    userDetailData();
  }, []);
  const userDetail = async () => {
    try {
      setLoader(true);
      let requestBody;
      requestBody = {
        userID: user._id
      };
      const response = await new AdminService().getUserDetail(requestBody);
      if (!response) {
        setLoader(false);
        Utils.failureToastMessage(
          failureMessageConstant.ERROR_WHILE_FETCHING_USER_DETAIL
        );
      } else {
        setUserDetailData(response);
        const filteredAssessments = response[0]?.assessments?.filter(
          (item) => item.reports.length > 0
        );

        setCurrentAssessment(filteredAssessments?.[0]?._id);
        setAssismentDetails(filteredAssessments?.[0]);
        setLoader(false);
      }
    } catch (err) {
      setLoader(false);
      Utils.failureToastMessage(
        failureMessageConstant.ERROR_WHILE_FETCHING_USER_DETAIL
      );
    }
  };
  return (
    <div className="flex flex-1 justify-between flex-col  overflow-y-auto rounded-tl-3xl bg-grey-f7f7f7">
      <div className="h-full w-285px md:block fixed bg-white">
        <div className="sticky top-0 left-0 h-[calc(100vh-60px)] w-full flex-col justify-between gap-1 py-4 md:flex">
          <div>
            <div className="mt-4 pl-4 font-Inter text-ft26-31 font-semibold text-black-15">
              Calculate your injury compensation
            </div>
            <div className="px-4">
              {(location.pathname === "/faq" ||
                location.pathname === "/history") && (
                <button
                  className="mt-3.5 flex h-11 w-full cursor-pointer items-center justify-center rounded-md border-none bg-[#146EF6] text-ft15-17 font-medium font-SF text-white outline-none"
                  onClick={() => {
                    Utils.localStorageRemoveItem(ASSISMENT_DETAIL);
                    Utils.localStorageRemoveItem(INJURY_TYPE);
                    Utils.localStorageRemoveItem(SURVEY_QUESTIONS);
                    navigate("/personal-details");
                  }}
                >
                  New Assessment
                </button>
              )}
            </div>

            {location.pathname === "/history" && (
              <div className="mt-10 px-0.5">
                <div className="font-Inter text-ft18-21 pl-4 font-semibold text-black-15 border-b border-grey-E3E3E3 pb-5">
                  History
                </div>
                <div className=" flex flex-col h-[456px] overflow-y-auto">
                  {userDetailData[0]?.assessments
                    .filter((item) => item.reports.length > 0)
                    .map((item, index) => (
                      <div
                        key={index}
                        className={`font-Inter flex items-center pl-4 min-h-11.5 h-11.5 text-ft15-18.5 border-b border-grey-E3E3E3 cursor-pointer ${item._id == currentAssessment ? "text-blue-146EF6 bg-grey-FAFAFA font-semibold" : "text-black-15 font-normal"}`}
                        onClick={() => {
                          setCurrentAssessment(item._id);
                          setAssismentDetails(item);
                        }}
                      >
                        {item?._id}
                      </div>
                    ))}
                </div>
              </div>
            )}
          </div>
          <div className="mb-20 px-4 font-Inter text-ft15-17 font-medium">
            <div
              className={`flex cursor-pointer items-center justify-between border-y border-grey-E3E3E3 py-3.5 ${location.pathname === "/history" ? "text-blue-146EF6" : "text-black-15"}`}
              onClick={() => {
                navigate("/history");
              }}
            >
              History
              <img
                src={
                  location.pathname === "/history"
                    ? "/images/arrow-colored-icon.svg"
                    : "/images/right-arrow.svg"
                }
                alt="arrow"
              />
            </div>
            <div
              className={`flex items-center cursor-pointer justify-between border-b border-grey-E3E3E3 py-3.5 ${location.pathname === "/faq" ? "text-blue-146EF6" : "text-black-15"}`}
              onClick={() => {
                navigate("/faq");
              }}
            >
              FAQs
              <img
                src={
                  location.pathname === "/faq"
                    ? "/images/arrow-colored-icon.svg"
                    : "/images/right-arrow.svg"
                }
                alt="arrow"
              />
            </div>
          </div>
        </div>
      </div>
      <div className=" flex flex-col items-center ml-285px ">
        <HistoryComponent
          userDetailData={userDetailData}
          currentAssessment={currentAssessment}
          assismentDetails={assismentDetails}
          loader={loader}
        />
        <div className="w-full flex justify-end pb-15 pr-11 font-Inter font-normal text-ft16-19.36 text-grey-757575 mt-20">
          LegalHero Helpline: 888-888-8888
        </div>
      </div>
    </div>
  );
}

export default History;
