import HTTPService from "../utility/httpService";
import { apiEndPointConstants, keyConstants } from "../constants";
import Cookies from "../managers/sessionManager";
const { USER_DETAIL } = apiEndPointConstants;
const { ACCESS_TOKEN } = keyConstants;

export default class AdminService {
  constructor(token) {
    this.httpService = new HTTPService(process.env.REACT_APP_ADMIN_SERVICE);
    this.config = {
      headers: {
        Authorization: `Bearer ${
          token !== undefined
            ? token
            : new Cookies().getCookieValue(ACCESS_TOKEN)
        }`
      }
    };
  }
  getUserDetail(requestData) {
    let url;
    url = `${USER_DETAIL}/${requestData?.userID}`;

    const requestBody = {};
    const headers = this.config;

    return this.httpService.get(url, requestBody, headers);
  }
}
