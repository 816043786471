/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import HTTPService from "../utility/httpService";
import { apiEndPointConstants, keyConstants } from "../constants";
import Cookies from "../managers/sessionManager";
const {
  PERSONAL_DETAIL,
  SURVEY_QUESTION,
  UPLOAD_REPORTS,
  INJURY_SURVEY_QUESTION,
  INJURY_COMPENSATION,
  ASSESSMENT_DETAIL
} = apiEndPointConstants;
const { ACCESS_TOKEN } = keyConstants;

export default class ClaimManagerService {
  constructor(token) {
    this.httpService = new HTTPService(
      process.env.REACT_APP_CLAIM_MANAGER_SERVICE
    );
    this.config = {
      headers: {
        Authorization: `Bearer ${
          token !== undefined
            ? token
            : new Cookies().getCookieValue(ACCESS_TOKEN)
        }`
      }
    };
  }
  addPersonalDetail(requestData) {
    const url = `${PERSONAL_DETAIL}`;
    const headers = this.config;
    return this.httpService.post(url, requestData, headers);
  }
  injurySurveyQuestion({ type }) {
    let typeValue = "";
    if (type === "fall-down") {
      typeValue = "fallDown";
    } else {
      typeValue = "motorVehicleAccident";
    }
    const url = `${SURVEY_QUESTION}?incidentType=${typeValue}&page=1&limit=100&isActive=true`;
    const headers = this.config;
    return this.httpService.get(url, null, headers);
  }
  uploadDocuments(requestData, userId) {
    console.log("requestData", requestData, userId);
    const url = `${UPLOAD_REPORTS}/${userId}`;
    const headers = this.config;
    return this.httpService.post(url, requestData, headers);
  }
  addInjurySurveyQuestion(requestData) {
    const url = `${INJURY_SURVEY_QUESTION}`;
    const headers = this.config;
    return this.httpService.post(url, requestData, headers);
  }
  updatePersonalDetail(requestData) {
    const url = `${PERSONAL_DETAIL}`;
    const headers = this.config;
    return this.httpService.put(url, requestData, headers);
  }

  addCompensationDetail(requestData) {
    const url = `${INJURY_COMPENSATION}`;
    const headers = this.config;
    return this.httpService.post(url, requestData, headers);
  }
  assessmentDetailById(id) {
    const url = `${ASSESSMENT_DETAIL}/${id}`;
    const headers = this.config;
    return this.httpService.get(url, null, headers);
  }
}
