/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable tailwindcss/classnames-order */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import UserService from "../../services/userService";
import Utils from "../../utility";
import CryptoJS from "crypto-js";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import { BiLoaderAlt } from "react-icons/bi";
import {
  failureMessageConstant,
  successMessageConstant,
  keyConstants,
  regexConstant
} from "../../constants";
import Auth0Service from "../../services/auth0Service";
import { useDispatch } from "react-redux";
import { removeUser } from "../../slices/userSlice";
import Cookies from "../../managers/sessionManager";
import { jwtDecode } from "jwt-decode";
const { USER, ACCESS_TOKEN } = keyConstants;
const useStyles = makeStyles((theme) => ({
  arrow: {
    "&:before": {
      border: "1px solid #146EF6"
    },
    color: theme.palette.common.white
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    border: "1px solid #146EF6",
    color: "#4A4A4A",
    width: "484px !important",
    height: "142px !important",
    borderRadius: "0px"
  }
}));

function ChangePasswordComponent() {
  let classes = useStyles();
  const navigate = useNavigate();
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isPasswordMasked, setIsPasswordMasked] = useState(true);
  const [isNewPasswordMasked, setIsNewPasswordMasked] = useState(true);
  const [isCofirmPasswordMasked, setIsConfirmPasswordMasked] = useState(true);
  const [loader, setLoader] = useState(false);
  const user = Utils.localStorageGetItem(USER);
  const dispatch = useDispatch();

  const checkTokenExpiration = (token) => {
    try {
      const decoded = jwtDecode(token);
      if (decoded.exp * 1000 < Date.now()) {
        return true;
      }
    } catch (e) {
      console.error("Invalid token");
    }
    return false;
  };

  const checkAccessToken = () => {
    const currentAccessToken = new Cookies().getCookieValue(ACCESS_TOKEN);
    if (!currentAccessToken || checkTokenExpiration(currentAccessToken)) {
      new Cookies().remove(ACCESS_TOKEN);
      Utils.localStorageClear();
      Utils.failureToastMessage(
        failureMessageConstant.SESSION_EXPIRED_PLEASE_LOGIN_AGAIN
      );
      navigate("/?type=login", { replace: true });
      return;
    }
  };

  useEffect(() => {
    const interval = setInterval(checkAccessToken, 2000);
    return () => clearInterval(interval);
  }, []);

  const currentPasswordValidate = async (password) => {
    try {
      setLoader(true);
      // Create an instance of Auth0Service
      const auth0Service = new Auth0Service();

      // Get the token using the user's email and password
      const response = auth0Service.getToken({
        email: user?.email,
        password
      });

      // Parse the response
      const [result] = await Utils.parseResponse(response);

      // Check if there is an error in the response data
      if (result?.error || result?.response?.data?.error) {
        setLoader(false);
        throw new Error("current password is incorrect");
      }

      // Password is valid
      return true;
    } catch (err) {
      setLoader(false);
      return false;
    }
  };
  const changePasswordHandler = async () => {
    if (!currentPassword.length) {
      Utils.failureToastMessage(
        failureMessageConstant.CURRENT_PASSWORD_FIELD_EMPTY
      );
      return;
    }
    if (!newPassword.length) {
      Utils.failureToastMessage(
        failureMessageConstant.NEW_PASSWORD_FIELD_EMPTY
      );
      return;
    }
    if (!regexConstant.PASSWORD_REGEX.test(newPassword)) {
      Utils.failureToastMessage(failureMessageConstant.INVALID_PASSWORD);
      return;
    }
    if (newPassword === currentPassword) {
      Utils.failureToastMessage(
        failureMessageConstant.NEW_PASSWORD_MUST_DIFFERENT
      );
      return;
    }
    if (newPassword !== confirmPassword) {
      Utils.failureToastMessage(failureMessageConstant.PASSWORD_MISMATCH);
      return;
    }
    try {
      // Check if the current password is valid
      const checkCurrentPassword =
        await currentPasswordValidate(currentPassword);
      // If current password is not valid, stop the submission
      if (!checkCurrentPassword) {
        Utils.failureToastMessage(
          failureMessageConstant.CURRENT_PASSWORD_NOT_MAICHING
        );
        setLoader(false);
        return;
      }
      // Get secret key from environment variables
      const secretKey = process.env.REACT_APP_ENCRYPTION_SECRET_KEY;
      // Encrypt the newPassword using AES encryption
      const encryptedNewPassword = CryptoJS.AES.encrypt(
        newPassword,
        secretKey
      ).toString();
      const [err, changePasswordRes] = await Utils.parseResponse(
        new UserService().changePassword({
          userId: user?.userId,
          newPassword: encryptedNewPassword
        })
      );

      if (err) {
        setLoader(false);
        console.log("err", err);
        Utils.failureToastMessage(
          failureMessageConstant.ERROR_WHILE_UPDATING_USER
        );
      } else {
        console.log("changePasswordRes", changePasswordRes);
        setLoader(false);
        navigate("/?type=login");
        dispatch(removeUser({}));
        Utils.successToastMessage(
          successMessageConstant.CHANGE_PASSWORD_SUCCESSFULLY
        );

        return changePasswordRes;
      }
    } catch (error) {
      setLoader(false);
      Utils.failureToastMessage(
        failureMessageConstant.ERROR_WHILE_CHANGING_PASSWORD
      );
    } finally {
      setLoader(false);
      /* empty */
    }
  };
  return (
    <div className=" my-28 flex w-full flex-col items-center">
      <div className="h-121 w-full max-w-109 rounded-4.5 bg-white px-7 py-8 shadow-modal">
        <div className="flex justify-center gap-1.5 font-Inter text-ft24-32 font-semibold">
          Change Password
          <img
            src="/images/cross-icon-change-password.svg"
            alt="changePassword"
            className="w-3.5 relative left-20 cursor-pointer"
            onClick={() => {
              navigate("/personal-details");
            }}
          />
        </div>
        <div className="mt-8 flex justify-between  gap-6 flex-col">
          <div className="w-full font-Inter text-ft14-20 font-normal text-grey-757575">
            <div className=" font-Inter font-medium text-black-15 text-ft16-19.36">
              Current Password
            </div>
            <div className="flex">
              <input
                type={isPasswordMasked ? "password" : "text"}
                className={`mt-0.5 h-49px w-97 rounded-lg border border-grey-E5E5E5 px-3 text-black-15 outline-none placeholder:text-ft13-15 placeholder:text-grey-BDBDBD pr-12 ${isPasswordMasked && currentPassword ? " text-ft24-32" : ""}`}
                placeholder="Enter existing password"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
              />
              {currentPassword && (
                <img
                  src={
                    !isPasswordMasked
                      ? "/images/eye-open.png"
                      : "/images/cross-eye-icon.svg"
                  }
                  alt="eye-icon"
                  className={`ml-[-36px] cursor-pointer ${!isPasswordMasked ? " mt-3.5 h-27px w-5.5" : ""}`}
                  onClick={() => {
                    setIsPasswordMasked(!isPasswordMasked);
                  }}
                />
              )}
            </div>
          </div>
          <div className="w-full font-Inter text-ft14-20 font-normal text-grey-757575">
            <div className=" font-Inter font-medium text-black-15 text-ft16-19.36 flex">
              New Password{" "}
              <Tooltip
                title={
                  <span className=" font-Inter font-normal text-ft16-19.36 text-black-15 w-121 flex flex-col gap-1 mt-2.5">
                    <div>1. Minimum length of 8 characters.</div>
                    <div>2. Includes at least one uppercase letter (A-Z).</div>
                    <div>3. Includes at least one lowercase letter (a-z).</div>
                    <div>4. Includes at least one number (0-9).</div>
                    <div>
                      5. Includes at least one special character (e.g.,
                      !@#$%^&*).
                    </div>
                  </span>
                }
                arrow
                classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
                placement="right-start"
                style={{ maxWidth: "484px" }}
              >
                <Button>
                  <img src="/images/tooltip-icon.svg" alt="info" />
                </Button>
              </Tooltip>
            </div>
            <div className=" flex">
              <input
                type={isNewPasswordMasked ? "password" : "text"}
                className={`mt-0.5 h-49px w-97 rounded-lg border border-grey-E5E5E5 px-3 text-black-15 outline-none placeholder:text-ft13-15 placeholder:text-grey-BDBDBD pr-12 ${isNewPasswordMasked && newPassword ? " text-ft24-32" : ""}`}
                placeholder="Enter new password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              {newPassword && (
                <img
                  src={
                    !isNewPasswordMasked
                      ? "/images/eye-open.png"
                      : "/images/cross-eye-icon.svg"
                  }
                  alt="eye-icon"
                  className={`ml-[-36px] cursor-pointer ${!isNewPasswordMasked ? " mt-3.5 h-27px w-5.5" : ""}`}
                  onClick={() => {
                    setIsNewPasswordMasked(!isNewPasswordMasked);
                  }}
                />
              )}
            </div>
          </div>
          <div className="w-full font-Inter text-ft14-20 font-normal text-grey-757575">
            <div className=" font-Inter font-medium text-black-15 text-ft16-19.36">
              Confirm Password
            </div>
            <div className="flex">
              <input
                type={isCofirmPasswordMasked ? "password" : "text"}
                className={`mt-0.5 h-49px w-97 rounded-lg border border-grey-E5E5E5 px-3 text-black-15 outline-none placeholder:text-ft13-15 placeholder:text-grey-BDBDBD pr-12 ${isCofirmPasswordMasked && confirmPassword ? " text-ft24-32" : ""}`}
                placeholder="Confirm new password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              {confirmPassword && (
                <img
                  src={
                    !isCofirmPasswordMasked
                      ? "/images/eye-open.png"
                      : "/images/cross-eye-icon.svg"
                  }
                  alt="eye-icon"
                  className={`ml-[-36px] cursor-pointer ${!isCofirmPasswordMasked ? " mt-3.5 h-27px w-5.5" : ""}`}
                  onClick={() => {
                    setIsConfirmPasswordMasked(!isCofirmPasswordMasked);
                  }}
                />
              )}
            </div>
          </div>
        </div>
        <button
          className="mt-12 h-53px w-97 rounded-lg bg-blue-146EF6 text-ft18-21 font-normal text-white"
          onClick={changePasswordHandler}
        >
          {loader ? (
            <div className=" flex justify-center">
              {" "}
              <BiLoaderAlt className="size-10 animate-spin text-white" />
            </div>
          ) : (
            "Change Password"
          )}
        </button>
      </div>
    </div>
  );
}

export default ChangePasswordComponent;
