/* eslint-disable no-console */
/* eslint-disable tailwindcss/no-unnecessary-arbitrary-value */
/* eslint-disable no-unused-vars */
import React from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Login from "./modules/login";
import PersonalDetails from "./modules/personalDetails";
import Header from "./common/components/header";
import { hideSidebarPaths, keyConstants } from "./constants";
import Sidebar from "./common/components/sidebar";
import InjurySurvey from "./modules/injurySurvey";
import { useSelector } from "react-redux";
import { CommonLoader } from "./common/components/loader";
import ChangePassword from "./modules/changePassword";
import Utils from "./utility";
import History from "./modules/history";
import Faq from "./modules/faq";
import AssessmentReport from "./modules/injurySurvey/assesmentReport";
import ForgetPassword from "./modules/forgetPassword";
import UserOtp from "./modules/userOtp";
const { USER } = keyConstants;

const ProtectedRoute = ({ user, children }) => {
  // Allow /forget-password route to be accessible from anywhere
  if (
    location.pathname === "/forget-password" ||
    location.pathname === "/user-otp"
  ) {
    return children;
  }
  if (!user || !user?.isLoggedIn) {
    return <Navigate to="/" replace />;
  }
  return children;
};

const PublicRoute = ({ user, children }) => {
  // Allow /forget-password route to be accessible from anywhere
  if (
    location.pathname === "/forget-password" ||
    location.pathname === "/user-otp"
  ) {
    return children;
  }

  if (user && user?.isLoggedIn) {
    return <Navigate to="/personal-details" replace />;
  }
  return children;
};

const Router = () => {
  const location = useLocation();

  const shouldHideSidebar = () => {
    return hideSidebarPaths.includes(location.pathname);
  };
  let isLoading = useSelector((state) => state.user.loading);
  const user = Utils.localStorageGetItem(USER);

  return (
    <div className="flex min-h-[100vh] flex-col">
      {isLoading && <CommonLoader />}
      {location.pathname !== "/" &&
        location.pathname !== "/forget-password" &&
        location.pathname !== "/user-otp" && <Header />}
      <div className="flex h-[calc(100vh-60px)] flex-1 flex-row overflow-hidden">
        {!shouldHideSidebar() ? <Sidebar className="flex-none" /> : ""}
        <Routes>
          <Route
            path="/forget-password"
            element={
              <PublicRoute user={user}>
                <ForgetPassword />
              </PublicRoute>
            }
          />
          <Route
            path="/user-otp"
            element={
              <PublicRoute user={user}>
                <UserOtp />
              </PublicRoute>
            }
          />
          <Route
            path="/"
            element={
              <PublicRoute user={user}>
                <Login />
              </PublicRoute>
            }
          />
          <Route
            path="/personal-details"
            element={
              <ProtectedRoute user={user}>
                <PersonalDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path="/injury-survey/:step?"
            element={
              <ProtectedRoute user={user}>
                <InjurySurvey />
              </ProtectedRoute>
            }
          />
          <Route
            path="/change-password"
            element={
              <ProtectedRoute user={user}>
                <ChangePassword />
              </ProtectedRoute>
            }
          />
          <Route
            path="/history"
            element={
              <ProtectedRoute user={user}>
                <History />
              </ProtectedRoute>
            }
          />
          <Route
            path="/faq"
            element={
              <ProtectedRoute user={user}>
                <Faq />
              </ProtectedRoute>
            }
          />
          <Route
            path="/report"
            element={
              <ProtectedRoute user={user}>
                <AssessmentReport />
              </ProtectedRoute>
            }
          />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </div>
    </div>
  );
};

export default Router;
