/* eslint-disable tailwindcss/no-custom-classname */
/* eslint-disable tailwindcss/classnames-order */
import React, { useState } from "react";

const VerificationInput = ({ length = 6, onComplete }) => {
  const [values, setValues] = useState(Array(length).fill(""));

  const handleChange = (e, index) => {
    const { value } = e.target;
    if (/^\d*$/.test(value)) {
      // Only accept digits
      const newValues = [...values];
      newValues[index] = value;

      setValues(newValues);

      // Move to the next input field if a digit is entered
      if (value && index < length - 1) {
        document.getElementById(`digit-${index + 1}`).focus();
      }

      onComplete(newValues.join(""));
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !values[index] && index > 0) {
      // Move to the previous input field on Backspace if the current one is empty
      document.getElementById(`digit-${index - 1}`).focus();
    }
  };

  return (
    <div className=" flex gap-2 justify-center">
      {values.map((val, index) => (
        <input
          key={index}
          id={`digit-${index}`}
          type="password"
          value={val}
          maxLength="1"
          onChange={(e) => handleChange(e, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          className="size-49px text-center text-ft36-40 border border-grey-E5E5E5 rounded-sm focus:outline-none flex justify-center pb-1"
        />
      ))}
    </div>
  );
};

export default VerificationInput;
