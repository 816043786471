/* eslint-disable no-console */
/* eslint-disable tailwindcss/enforces-shorthand */
/* eslint-disable tailwindcss/classnames-order */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import {
  failureMessageConstant,
  keyConstants,
  UPLOAD_DOCUMENTS
} from "../../constants";
import Utils from "../../utility";

const UploadDocuments = ({
  uploadedFiles,
  setUploadedFiles,
  uploadDocumentPage,
  setUploadDocumentPage
}) => {
  const handleFileChange = (index, event) => {
    const files = Array.from(event.target.files);
    const pdfFiles = files.filter((file) => file.type === "application/pdf");

    if (pdfFiles.length !== files.length) {
      Utils.failureToastMessage(
        failureMessageConstant.ONLY_PDF_FILE_ARE_ALLOWED
      );
      return;
    }

    setUploadedFiles((prev) => {
      const updatedFiles = { ...prev };
      if (!updatedFiles[index]) {
        updatedFiles[index] = [];
      }
      updatedFiles[index] = [...updatedFiles[index], ...pdfFiles];
      return updatedFiles;
    });
    setUploadDocumentPage(2);
  };

  const handleDeleteFile = (index, fileName) => {
    setUploadedFiles((prev) => {
      const updatedFiles = { ...prev };
      updatedFiles[index] = updatedFiles[index].filter(
        (file) => file.name !== fileName
      );
      return updatedFiles;
    });
  };

  const [dragging, setDragging] = useState(false);

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
    const files = e.dataTransfer.files;
    handleFileChange(0, { target: { files } });
  };

  return (
    <div
      className={`${uploadDocumentPage === 2 ? "h-[409px] overflow-y-auto" : ""}`}
    >
      {uploadDocumentPage === 1 && (
        <>
          <div className=" font-Inter font-normal text-ft16-24 text-black-15 w-527px text-center">
            Please upload MRI, XRAY, CT SCAN, SURGERY REPORT, Official Incident
            Report(Compulsory) here.
          </div>
          <div
            className="w-531px h-80 border-dashed border border-blue-146EF6 rounded-xl flex justify-center items-center flex-col mt-10.5 mx-auto"
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            onClick={() => document.getElementById("fileInput").click()}
          >
            <div className="w-105px h-105px bg-blue-F3F8FF flex justify-center items-center rounded-full cursor-pointer">
              <img src="/images/plus-icon-upload.svg" alt="plus-icon" />
            </div>
            <input
              id={"fileInput"}
              type="file"
              multiple
              accept=".pdf" // Only accept PDF files
              style={{ display: "none" }}
              onChange={(event) => handleFileChange(0, event)}
            />
            <div className=" font-PoppinsMedium font-medium text-ft14-20 text-black-15 mt-2 cursor-pointer">
              Drag and drop or <span className=" text-blue-146EF6">Browse</span>
            </div>
            <div className=" font-PoppinsRegular font-normal text-ft14-20 text-grey-9FACBA">
              Supported formats : PDF
            </div>
          </div>
        </>
      )}

      {uploadDocumentPage === 2 &&
        uploadedFiles[0] &&
        uploadedFiles[0].map((file, fileIndex) => (
          <>
            <div
              key={fileIndex}
              className="mb-3.5 flex items-center justify-between gap-2"
            >
              <div className=" flex items-center gap-1.5">
                <img
                  src="/images/uploaded-image.svg"
                  alt="uploaded-image"
                  className=" w-6.5 h-6.5"
                />
                <p className="font-Inter font-medium text-ft15-17 text-black-15">
                  {file.name}
                </p>
              </div>
              <img
                onClick={() => handleDeleteFile(0, file.name)}
                src="/images/delete-icon.svg"
                className=" cursor-pointer"
              />
            </div>

            <hr className="mb-3.5 text-grey-E5E5E5" />
          </>
        ))}
    </div>
  );
};

export default UploadDocuments;
