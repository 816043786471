/* eslint-disable tailwindcss/no-custom-classname */
/* eslint-disable tailwindcss/classnames-order */
import React from "react";

const EmailPopupComponent = () => {
  return (
    <div>
      <div className=" flex justify-center mt-10">
        <span className=" size-99px bg-blue-E7F0FF rounded-full flex pr-1 items-center">
          <img
            src="/images/inbox.svg"
            alt="inbox"
            className=" min-w-103px w-103px relative right-2 h-40.70px"
          />
        </span>
      </div>
      <div className=" flex justify-center">
        <div className=" font-Inter font-normal text-ft18-21 text-black-15 mt-12 w-100 text-center">
          Once your report is generated it will be sent to you on your email
        </div>
      </div>
    </div>
  );
};

export default EmailPopupComponent;
