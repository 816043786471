/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable tailwindcss/classnames-order */
/* eslint-disable react/no-unknown-property */
/* eslint-disable tailwindcss/no-unnecessary-arbitrary-value */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable tailwindcss/enforces-shorthand */
/* eslint-disable tailwindcss/no-custom-classname */
import React, { useEffect, useState } from "react";
import { ReactComponent as Logo } from "../../assets/images/logo-white.svg";
import { ReactComponent as LoginContent } from "../../assets/images/login-content.svg";
import { useNavigate } from "react-router";
import { BiLoaderAlt } from "react-icons/bi";
import {
  failureMessageConstant,
  regexConstant,
  successMessageConstant
} from "../../constants";
import Utils from "../../utility";
import UserService from "../../services/userService";

const ForgetPasswordComponent = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [loader, setLoader] = useState(false);

  const forgetPasswordApiCall = async () => {
    try {
      setLoader(true);
      await new UserService().forgotPassword({
        email
      });
      Utils.successToastMessage(
        successMessageConstant.FORGET_PASSWORD_MAIL_SENT_SUCCESSFULLY
      );
      setLoader(false);
    } catch (err) {
      setLoader(false);
      Utils.failureToastMessage(
        failureMessageConstant.USER_DOES_NOT_EXIST_CONTACT_ADMIN
      );
    } finally {
      /* empty */
    }
  };
  const forgetPasswordHandler = async () => {
    if (!regexConstant.EMAIL_REGEX.test(email)) {
      Utils.failureToastMessage(failureMessageConstant.INVALID_EMAIL);
      return;
    } else {
      await forgetPasswordApiCall();
    }
  };
  return (
    <>
      <div className="flex size-full">
        <div className=" flex min-h-[100vh] w-full flex-col items-center justify-between bg-white pt-24 pb-10 gap-10">
          <div className=" flex items-center flex-col">
            <Logo />
            <div className=" mt-24 h-108 w-110 rounded-lg shadow-modal">
              <div className=" font-Inter font-semibold text-ft24-29 text-black-15 flex justify-center pt-6">
                Forget Password
              </div>
              <div className=" flex justify-center mt-10">
                <div className=" font-Inter font-normal text-ft15-18.5 text-black-15 w-[388px]">
                  Enter the email address associated with your account and we'll
                  send you a link to reset your password.
                </div>
              </div>
              <div className="mt-8 flex justify-between  gap-6 flex-col">
                <div className="w-full font-Inter text-ft14-20 font-normal text-grey-757575 px-6">
                  <div className=" font-Inter font-medium text-black-15 text-ft16-19.36">
                    Email
                  </div>
                  <input
                    type="text"
                    className="mt-0.5 h-49px w-97 rounded-lg border border-grey-E5E5E5 px-3 text-black-15 outline-none placeholder:text-ft13-15 placeholder:text-grey-BDBDBD"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </div>
              </div>
              <button
                className="mt-10 h-53px w-97 rounded-lg bg-blue-146EF6 font-SF font-medium text-ft18-21 text-white ml-6"
                onClick={forgetPasswordHandler}
              >
                {loader ? (
                  <div className=" flex justify-center">
                    {" "}
                    <BiLoaderAlt className="size-10 animate-spin text-white" />
                  </div>
                ) : (
                  " Continue"
                )}
              </button>
              <div className="mb-3 flex w-full justify-center font-Inter text-ft15-17 font-normal mt-15">
                Don’t have an account?
                <span
                  className="ml-1 text-blue-146EF6 cursor-pointer"
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  Sign Up
                </span>
              </div>
            </div>
          </div>

          <div className="h-9 w-41.5 text-center font-Inter text-ft15-17 font-medium tracking-tight text-black-15">
            2024 All Right Reserved Privacy and Terms
          </div>
        </div>
        <div className=" flex min-h-[100vh] w-full max-w-[680px] flex-col items-center bg-[#146EF6]">
          <span className="mt-[12%] w-full max-w-[75%] text-center text-ft30-36 font-bold text-white">
            Discover Your Injury Compensation Value with Ease!
          </span>
          <LoginContent className=" mt-[13%]" />
        </div>
      </div>
    </>
  );
};

export default ForgetPasswordComponent;
