/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable tailwindcss/enforces-shorthand */
/* eslint-disable tailwindcss/classnames-order */
import React, { useState } from "react";
import { ASSESSMENT_DETAILS, INJURY_TYPES } from "../../constants";
import { BiLoaderAlt } from "react-icons/bi";

function HistoryComponent({ assismentDetails, loader }) {
  const convertEpochToDate = (epochTimeInSeconds) => {
    // Convert seconds to milliseconds
    const epochTimeInMilliseconds = epochTimeInSeconds * 1000;

    // Create a new Date object
    const date = new Date(epochTimeInMilliseconds);

    // Format the date (e.g., MM/DD/YYYY)
    const formattedDate = date.toLocaleDateString("en-US");

    return formattedDate;
  };
  const ASSESSMENT_DETAILS = [
    {
      name: "Assessment ID",
      assessmentId: 0,
      value: assismentDetails?._id || "-"
    },
    {
      name: "Name of Injured",
      assessmentId: 1,
      value:
        assismentDetails?.firstName + " " + assismentDetails?.lastName || "-"
    },
    {
      name: "Date of Birth",
      assessmentId: 2,
      value: assismentDetails?.dateOfBirth ? assismentDetails?.dateOfBirth : "-"
    },
    {
      name: "Date of Injury",
      assessmentId: 3,
      value: assismentDetails?.dateOfInjury
        ? assismentDetails?.dateOfInjury
        : "-"
    },
    {
      name: "Place of Injury",
      assessmentId: 4,
      value: assismentDetails?.placeOfInjury || "-"
    },
    {
      name: "ZIP Code",
      assessmentId: 5,
      value: assismentDetails?.zipCode || "-"
    }
  ];
  return (
    <div
      className={
        "h-148.5 pb-7 mt-28.5 w-full max-w-152 rounded-4.5 bg-white pl-7 pr-7 pt-8 shadow-modal"
      }
    >
      {loader ? (
        <div className=" flex justify-center items-center h-full">
          {" "}
          <BiLoaderAlt className="size-10 animate-spin text-blue-146EF6" />
        </div>
      ) : (
        <>
          <div className="flex justify-between">
            <div className="flex w-full gap-1.5 font-Inter text-ft24-32 font-semibold mb-2.5">
              Assessment Report
            </div>
          </div>
          <div className="h-auto rounded-2.5 bg-blue-F1F7FF px-5 py-[13px] flex flex-col gap-2.5">
            {ASSESSMENT_DETAILS.map((item, index) => (
              <div key={index}>
                <div className=" flex items-center justify-between">
                  <p className="font-Inter text-ft14-20 font-normal text-grey-25">
                    {item?.name}
                  </p>
                  <div className="flex gap-1">
                    {/* <img
                  className={`${index === 3 || index === 4 ? "block" : "hidden"}`}
                  src="/images/verified.svg"
                /> */}
                    <p className="font-Inter text-ft15-17 font-medium text-black-15">
                      {item?.name === "Date of Injury" ||
                      item?.name === "Date of Birth"
                        ? convertEpochToDate(item?.value)
                        : item?.value}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="mt-4">
            <div className=" mb-2 flex items-center justify-between px-2.5">
              <p className=" font-Inter text-ft14-20 font-bold text-black-15">
                Injury
              </p>
              <p className=" font-Inter text-ft14-20 font-bold text-black-15">
                Compensation
              </p>
            </div>
            <hr className=" text-grey-DFDFDF" />
            {INJURY_TYPES.map((item, index) => (
              <div key={index}>
                <div className="mt-2.5 flex items-center justify-between px-2.5">
                  <p className="font-Inter text-ft15-17 font-normal text-black-15">
                    {item?.name}
                  </p>
                  <p className="font-Inter text-ft15-17 font-medium text-black-15">
                    {item?.amount}
                  </p>
                </div>
                <p className="mb-2.5 mt-1.5 font-Inter text-ft14-20 font-normal text-grey-35 px-2.5">
                  {item?.conclusion}
                </p>
                <hr className=" border-t border-dashed border-grey-A2A2A2" />
              </div>
            ))}
            <div className="mt-2.5 flex items-center justify-between px-2.5">
              <p className="font-Inter text-ft15-17 font-normal text-black-15">
                Total Compensation
              </p>
              <p className="font-Inter text-ft15-17 font-medium text-black-15">
                $17,065.00
              </p>
            </div>
            <div className="mt-2 flex items-center justify-between px-2.5">
              <p className="mb-2.5 font-Inter text-ft15-17 font-normal text-red-FB5A5A">
                Late medical treatment (-10%)
              </p>
              <p className="mb-2.5 mt-1.5 font-Inter text-ft15-17 font-medium text-red-FB5A5A">
                $1706.55
              </p>
            </div>
            <hr className="mb-3 mt-2 text-grey-DFDFDF" />
            <div className="flex items-center justify-between px-2.5">
              <p className="font-Inter text-ft18-21 font-semibold text-black-15">
                Total Case Value
              </p>
              <p className="font-Inter text-ft18-21 font-semibold text-black-15">
                $15,358.45
              </p>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default HistoryComponent;
