/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable tailwindcss/classnames-order */
/* eslint-disable prettier/prettier */
/* eslint-disable tailwindcss/no-custom-classname */
import React, { useEffect, useState } from "react";
import { ReactComponent as Logo } from "../../assets/images/logo-white.svg";
import { useDispatch, useSelector } from "react-redux";
import { removeUser } from "../../slices/userSlice";
import { useNavigate } from "react-router-dom";
import Utils from "../../utility";
import {
  successMessageConstant,
  keyConstants,
  failureMessageConstant
} from "../../constants";
import UserService from "../../services/userService";
const { USER, AUTH_ID } = keyConstants;

const Header = () => {
  const [isLogoutPopupOpen, setIsLogoutPopupOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = Utils.localStorageGetItem(USER);
  // FUNCTION TO LOGOUT USER
  // NEED TO APPLY THIS FUNCTION ON LOGOUT BUTTON WHEN UI IS CREATED
  const updateLastLogin = async () => {
    try {
      const [err, updateUserRes] = await Utils.parseResponse(
        new UserService().userLastLogin({
          id: user?._id
        })
      );
      if (err) {
        Utils.failureToastMessage(
          failureMessageConstant.NOT_ABLE_TO_UPDATE_LAST_LOGIN
        );
      } else {
        //
      }
    } catch (error) {
      Utils.failureToastMessage(
        failureMessageConstant.NOT_ABLE_TO_UPDATE_LAST_LOGIN
      );
    } finally {
      /* empty */
    }
  };
  const logoutUser = async () => {
    await updateLastLogin();
    dispatch(removeUser({}));
    Utils.localStorageRemoveItem(AUTH_ID);
    navigate("/");
    Utils.successToastMessage(successMessageConstant.LOGOUT_SUCCESSFULLY);
  };
  const changePassword = () => {
    setIsLogoutPopupOpen(false);
    navigate("/change-password");
  };
  useEffect(() => {
    if (!user?.isLoggedIn) return navigate("/");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.isLoggedIn]);
  return (
    <div className="sticky top-0 flex h-15 items-center justify-between bg-white pl-2 pr-8 z-50">
      <Logo className="h-9" />
      <div className="flex items-center gap-4">
        <div
          onClick={() => {
            setIsLogoutPopupOpen(!isLogoutPopupOpen);
          }}
          className="flex size-8 cursor-pointer items-center justify-center rounded-full bg-grey-f7f7f7 font-Inter text-ft16-24 font-normal text-darkGrey-808080 capitalize"
        >
          {user?.fullName?.replace(/^@+/, "")[0]}
        </div>
        <div className="relative">
          {isLogoutPopupOpen && (
            <div
              className={
                " shadow-logout $ absolute right-2.5 top-6 flex w-165px h-16.5  cursor-pointer flex-col rounded-md bg-white font-PoppinsMedium text-black-10"
              }
            >
              <div
                className="flex items-center pl-3.5 cursor-pointer h-1/2 pt-1"
                onClick={changePassword}
              >
                <img
                  src="/images/change-password-icon.svg"
                  alt="change-password"
                  className="size-3.5 mr-1"
                />
                <div className="font-Inter text-ft14-17 font-normal text-black-15">
                  Change Password
                </div>
              </div>
              <div
                className="flex items-center pl-3.5 cursor-pointer h-1/2 pb-1"
                onClick={logoutUser}
              >
                <img
                  src="/images/logout-icon.svg"
                  alt="logout"
                  className="size-3.5 mr-1"
                />
                <div className="font-Inter text-ft14-17 font-normal text-black-15">
                  Log Out
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
