/* eslint-disable tailwindcss/no-custom-classname */
/* eslint-disable no-unused-vars */
/* eslint-disable tailwindcss/no-contradicting-classname */
/* eslint-disable tailwindcss/classnames-order */
import React, { useState } from "react";
import { faq } from "../../constants";

function FaqComponent() {
  // Initialize state to track which answer is open
  const [openIndex, setOpenIndex] = useState(0); // The first answer is always open

  // Handler to toggle answer visibility
  const handleToggle = (index) => {
    setOpenIndex(index);
  };
  return (
    <div
      className={
        "pb-5 w-full max-w-[1111px] min-h-[743px] rounded-4.5 bg-white pl-7 pr-[31px] pt-8 shadow-modal"
      }
    >
      <div className="font-Inter text-ft24-32 font-semibold text-black-15 mb-10">
        Frequently Asked Questions
      </div>
      <div>
        {faq.map((item, index) => (
          <div key={index} className="border-b border-b-grey-E5E5E5 pb-4">
            <div className="font-Inter text-ft15-24 font-semibold text-black-15 flex justify-between pt-4 pr-6">
              {item.q}
              <img
                src={
                  index == openIndex
                    ? "/images/top-arrow.svg"
                    : "/images/down-arrow.svg"
                }
                alt="down-arrow"
                className="cursor-pointer"
                onClick={() => handleToggle(index)}
              />
            </div>
            {openIndex === index && (
              <div className=" font-Inter font-normal text-ft15-24 text-black-15 w-[87%]">
                {item.ans}
              </div>
            )}
          </div>
        ))}
      </div>
      <div className=" font-Inter font-normal text-black-15 text-ft14-24 mt-8">
        Need More Help?
      </div>
      <div className="font-Inter font-normal  text-blue-146EF6 text-ft15-24">
        support@sobosobo.com
      </div>
    </div>
  );
}

export default FaqComponent;
