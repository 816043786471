import { createSlice } from "@reduxjs/toolkit";
import Utils from "../utility";
import { keyConstants } from "../constants";
import Cookies from "../managers/sessionManager";
const { USER, ID, ACCESS_TOKEN, AUTH_ID, EMAIL_ID, IS_GOOGLE_LOGIN } =
  keyConstants;
const initialState = {
  isLoggedIn: false,
  userId: "",
  email: "",
  id: Utils.localStorageGetItem(ID) || null,
  loading: false
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateUser: (state, action) => {
      Utils.localStorageSetItem(USER, action.payload);
      return {
        ...state,
        ...action.payload
      };
    },
    removeUser: (state, action) => {
      new Cookies().remove(ACCESS_TOKEN);
      sessionStorage.removeItem(EMAIL_ID);
      sessionStorage.removeItem(IS_GOOGLE_LOGIN);
      // Store the authid value
      const authId = localStorage.getItem(AUTH_ID);
      Utils.localStorageClear();
      // Restore the authid value
      if (authId !== null) {
        localStorage.setItem(AUTH_ID, authId);
      }
      action.payload = Object.fromEntries(
        Object.keys(state || {}).map((key) => [key, ""])
      );
      action.payload.isLoggedIn = false;
      return {
        ...state,
        ...action.payload
      };
    },
    showLoader: (state) => {
      return {
        ...state,
        loading: true
      };
    },
    hideLoader: (state) => {
      return {
        ...state,
        loading: false
      };
    }
  }
});

export const { updateUser, removeUser, showLoader, hideLoader } =
  userSlice.actions;

export default userSlice.reducer;
