/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable quotes */
/* eslint-disable tailwindcss/classnames-order */
import React from "react";
import InjurySurveyComponent from "./injurySurveyComponent";
import { useLocation } from "react-router";

const InjurySurvey = () => {
  const location = useLocation();
  console.log("location", location.pathname);
  return (
    <div
      className={`flex flex-1 items-center overflow-y-auto rounded-tl-3xl bg-grey-f7f7f7 flex-col gap-5 ml-285px ${
        location.pathname === "/injury-survey/2"
          ? ""
          : location.pathname === "/injury-survey/4"
            ? " justify-between"
            : "justify-between"
      }`}
    >
      <InjurySurveyComponent />
      <div
        className={`w-full flex justify-end pr-11 font-Inter font-normal text-ft16-19.36 text-grey-757575 ${
          location.pathname === "/injury-survey/2" ? " pb-2" : " pb-15"
        }`}
      >
        LegalHero Helpline: 888-888-8888
      </div>
    </div>
  );
};

export default InjurySurvey;
