/* eslint-disable tailwindcss/classnames-order */
import React from "react";
import { sidebarItems, historyDummyIds, keyConstants } from "../../constants";
import { useLocation, useNavigate } from "react-router-dom";
import Utils from "../../utility";
const { ASSISMENT_DETAIL, INJURY_TYPE, SURVEY_QUESTIONS } = keyConstants;

const Sidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isActivePath = (menuPath, locationPath) => {
    if (menuPath === "/personal-details") {
      return (
        locationPath.startsWith("/personal-details") ||
        locationPath.startsWith("/injury-survey")
      );
    }
    if (menuPath === "/injury-survey") {
      return locationPath.startsWith("/injury-survey");
    }
    if (menuPath.startsWith("/injury-survey/")) {
      const pathNumber = menuPath.split("/").pop();
      const locationNumber = locationPath.split("/").pop();
      return (
        locationPath.startsWith("/injury-survey") &&
        Number(locationNumber) >= Number(pathNumber)
      );
    }
    return locationPath === menuPath;
  };

  return (
    <div className="h-full w-285px md:block fixed bg-white">
      <div className="sticky top-0 h-[calc(100vh-60px)] w-full flex-col justify-between gap-1 py-4 md:flex">
        <div>
          <div className="mt-4 pl-4 font-Inter text-ft26-31 font-semibold text-black-15">
            Calculate your injury compensation
          </div>
          <div className="px-4">
            {(location.pathname === "/faq" ||
              location.pathname === "/history") && (
              <button
                className="mt-3.5 flex h-11 w-full cursor-pointer items-center justify-center rounded-md border-none bg-[#146EF6] text-ft15-17 font-medium font-SF text-white outline-none"
                onClick={() => {
                  Utils.localStorageRemoveItem(ASSISMENT_DETAIL);
                  Utils.localStorageRemoveItem(INJURY_TYPE);
                  Utils.localStorageRemoveItem(SURVEY_QUESTIONS);
                  navigate("/personal-details");
                }}
              >
                New Assessment
              </button>
            )}
          </div>

          {location.pathname === "/history" && (
            <div className="mt-10 px-0.5">
              <div className="font-Inter text-ft18-21 pl-4 font-semibold text-black-15 border-b border-grey-E3E3E3 pb-5">
                History
              </div>
              <div className=" flex flex-col">
                {historyDummyIds.map((item, index) => (
                  <div
                    key={index}
                    className={`font-Inter flex items-center pl-4 h-11.5 text-ft15-18.5 border-b border-grey-E3E3E3 cursor-pointer ${index == 0 ? "text-blue-146EF6 bg-grey-FAFAFA font-semibold" : "text-black-15 font-normal"}`}
                  >
                    {item}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        {location.pathname !== "/change-password" &&
          location.pathname !== "/history" &&
          location.pathname !== "/faq" && (
            <div className="relative flex flex-col px-4 font-Inter text-ft15-17 font-medium">
              {sidebarItems.map((menu, index) => {
                const isNextActive =
                  sidebarItems[index + 1] &&
                  isActivePath(sidebarItems[index + 1].path, location.pathname);
                return (
                  <>
                    <div
                      className={`flex h-10.5 items-center gap-2.5 rounded-md px-2 ${isActivePath(menu.path, location.pathname) ? "bg-blue-EDF4FF text-blue-146EF6" : "bg-white"}`}
                      key={index}
                    >
                      <img
                        src={
                          isActivePath(menu.path, location.pathname)
                            ? menu.activeIcon
                            : menu.icon
                        }
                        alt={menu.name}
                      />
                      {menu.name}
                    </div>
                    {index < sidebarItems.length - 1 && (
                      <div className=" flex flex-col gap-3">
                        <div
                          className={`relative left-4.5 h-1 w-px border ${isNextActive ? "border-blue-146EF6" : "border-grey-B8B8B9"}`}
                        ></div>
                        <div
                          className={`relative left-4.5 h-3 w-px border ${isNextActive ? "border-blue-146EF6" : "border-grey-B8B8B9"}`}
                        ></div>
                        <div
                          className={`relative left-4.5 h-1 w-px border ${isNextActive ? "border-blue-146EF6" : "border-grey-B8B8B9"}`}
                        ></div>
                      </div>
                    )}
                  </>
                );
              })}
            </div>
          )}
        <div className="mb-20 px-4 font-Inter text-ft15-17 font-medium">
          <div
            className={`flex cursor-pointer items-center justify-between border-y border-grey-E3E3E3 py-3.5 ${location.pathname === "/history" ? "text-blue-146EF6" : "text-black-15"}`}
            onClick={() => {
              navigate("/history");
            }}
          >
            History
            <img
              src={
                location.pathname === "/history"
                  ? "/images/arrow-colored-icon.svg"
                  : "/images/right-arrow.svg"
              }
              alt="arrow"
            />
          </div>
          <div
            className={`flex items-center cursor-pointer justify-between border-b border-grey-E3E3E3 py-3.5 ${location.pathname === "/faq" ? "text-blue-146EF6" : "text-black-15"}`}
            onClick={() => {
              navigate("/faq");
            }}
          >
            FAQs
            <img
              src={
                location.pathname === "/faq"
                  ? "/images/arrow-colored-icon.svg"
                  : "/images/right-arrow.svg"
              }
              alt="arrow"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
