/* eslint-disable tailwindcss/classnames-order */
import React from "react";
import ChangePasswordComponent from "./changePasswordComponent";

function ChangePassword() {
  return (
    <div className="flex-1 ml-285px flex flex-col justify-between overflow-y-auto rounded-tl-3xl bg-grey-f7f7f7">
      <ChangePasswordComponent />
      <div className="w-full flex justify-end pb-15 pr-11 font-Inter font-normal text-ft16-19.36 text-grey-757575">
        LegalHero Helpline: 888-888-8888
      </div>
    </div>
  );
}

export default ChangePassword;
