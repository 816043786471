/* eslint-disable tailwindcss/classnames-order */
import React from "react";
import PersonalDetailsComponent from "./personalDetailsComponent";

const PersonalDetails = () => {
  return (
    <div className="flex-1 overflow-y-auto rounded-tl-3xl bg-grey-f7f7f7 ml-285px">
      <PersonalDetailsComponent />
    </div>
  );
};

export default PersonalDetails;
