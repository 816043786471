/* eslint-disable tailwindcss/classnames-order */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import SurveyOptions from "./surveyOptions";
import {
  failureMessageConstant,
  keyConstants,
  successMessageConstant,
  tooltipContent
} from "../../constants";
import UploadDocuments from "./uploadDocuments";
import AssessmentReport from "./assesmentReport";
import { useNavigate } from "react-router-dom";
import ClaimManagerService from "../../services/claimManagerService";
import Utils from "../../utility";
import { BiLoaderAlt } from "react-icons/bi";
import Cookies from "../../managers/sessionManager";
import { jwtDecode } from "jwt-decode";
import EmailPopupComponent from "./emailPopupNotification";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
const { USER, ASSISMENT_DETAIL, INJURY_TYPE, SURVEY_QUESTIONS, ACCESS_TOKEN } =
  keyConstants;
const useStyles = makeStyles((theme) => ({
  arrow: {
    "&:before": {
      border: "1px solid #146EF6"
    },
    color: theme.palette.common.white
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    border: "1px solid #146EF6",
    color: "#4A4A4A",
    width: "484px !important",
    minHeight: "50px !important",
    borderRadius: "0px"
  }
}));

const InjurySurveyComponent = () => {
  let classes = useStyles();
  const navigate = useNavigate();
  const checkTokenExpiration = (token) => {
    try {
      const decoded = jwtDecode(token);
      if (decoded.exp * 1000 < Date.now()) {
        return true;
      }
    } catch (e) {
      console.error("Invalid token");
    }
    return false;
  };
  const checkAccessToken = () => {
    const currentAccessToken = new Cookies().getCookieValue(ACCESS_TOKEN);
    if (!currentAccessToken || checkTokenExpiration(currentAccessToken)) {
      new Cookies().remove(ACCESS_TOKEN);
      Utils.localStorageClear();
      Utils.failureToastMessage(
        failureMessageConstant.SESSION_EXPIRED_PLEASE_LOGIN_AGAIN
      );
      navigate("/?type=login", { replace: true });
      return;
    }
  };

  useEffect(() => {
    const interval = setInterval(checkAccessToken, 2000);
    return () => clearInterval(interval);
  }, []);
  const injuryType = Utils.localStorageGetItem(INJURY_TYPE);
  const surveyQuestionsValues = Utils.localStorageGetItem(SURVEY_QUESTIONS);
  const [activeImage, setActiveImage] = useState(
    injuryType !== null ? injuryType : ""
  );
  const [injuryStep, setInjuryStep] = useState(1);
  const [showRecalculateButton, setShowRecalculateButton] = useState(false);
  const [surveyQuestions, setSurveyQuestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [surveyValue, setSurveyValue] = useState(
    surveyQuestionsValues !== null ? surveyQuestionsValues : []
  );
  const [uploadedFiles, setUploadedFiles] = useState({});
  const user = Utils.localStorageGetItem(USER);
  const [loadingUploadDocuments, setLoadingUploadDocuments] = useState(false);
  const [loadingInjurySurvey, setLoadingInjurySurvey] = useState(false);
  const [uploadDocumentPage, setUploadDocumentPage] = useState(1);

  // Handler to update survey answers
  const handleSurveyChange = (question, answer, id) => {
    setSurveyValue((prev) => {
      const existing = prev.find((item) => item.question === question);
      if (existing) {
        // Update the existing answer
        return prev.map((item) =>
          item.question === question ? { question, answer, id } : item
        );
      } else {
        // Add a new answer
        return [...prev, { question, answer, id }];
      }
    });
  };

  const handleBackArrow = () => {
    if (injuryStep === 1) {
      navigate("/personal-details");
    }
    if (injuryStep === 3 && uploadDocumentPage === 2) {
      setUploadDocumentPage(1);
      return;
    }
    if (injuryStep > 1) {
      setShowRecalculateButton(false);
      setInjuryStep((prevStep) => {
        const newStep = prevStep - 1;
        navigate(
          `${window.location.pathname.replace(/\/\d+$/, "")}/${newStep}`
        );
        return newStep;
      });
    }
  };
  useEffect(() => {
    if (injuryStep === 1) {
      navigate(`${window.location.pathname.replace(/\/\d+$/, "")}/${1}`);
    }
  }, []);

  useEffect(() => {
    if (injuryStep === 4) {
      const timer = setTimeout(() => {
        setShowRecalculateButton(true);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [injuryStep]);

  const incidentTypeHandler = async (type) => {
    if (injuryType !== type) {
      Utils.localStorageRemoveItem(SURVEY_QUESTIONS);
      setSurveyValue([]);
    }
    setActiveImage(type);
    setLoading(true);
    try {
      setLoading(true);
      const [err, injurySurveyQuestionResponse] = await Utils.parseResponse(
        new ClaimManagerService().injurySurveyQuestion({ type })
      );
      console.log("err", injurySurveyQuestionResponse, err);

      if (err) {
        setLoading(false);
        Utils.failureToastMessage(
          failureMessageConstant.ERROR_WHILE_FETCHING_INJURY_SURVEY_QUESTION
        );
      } else {
        Utils.localStorageSetItem(INJURY_TYPE, type);
        setSurveyQuestions(injurySurveyQuestionResponse?.surveyQuestions);
        setLoading(false);
        setInjuryStep(2);
        navigate(`${window.location.pathname.replace(/\/\d+$/, "")}/2`);
        Utils.successToastMessage(
          successMessageConstant.INJURY_QUESTION_FETCHED_SUCCESSFULLY
        );

        return injurySurveyQuestionResponse;
      }
    } catch (error) {
      setLoading(false);
      Utils.failureToastMessage(
        failureMessageConstant.ERROR_WHILE_FETCHING_INJURY_SURVEY_QUESTION
      );
    } finally {
      setLoading(false);
      /* empty */
    }
  };
  const injuryComensationHandler = async () => {
    const assessmentID = JSON.parse(localStorage.getItem(ASSISMENT_DETAIL));
    const requestData = {
      assessmentId: assessmentID?._id,
      amount: 60000,
      totalCompensation: 15000,
      lateMedicalTreatment:
        "Patient received treatment 2 weeks after the injury",
      totalCaseValue: 20000,
      injury: "Fall Down"
    };
    try {
      const [err, datails] = await Utils.parseResponse(
        new ClaimManagerService().addCompensationDetail(requestData)
      );

      if (err) {
        Utils.failureToastMessage(
          failureMessageConstant.ERROR_WHILE_CALLING_API_DOCUMENT
        );
      } else {
        return datails;
      }
    } catch (error) {
      Utils.failureToastMessage(
        failureMessageConstant.ERROR_WHILE_CALLING_API_DOCUMENT
      );
    } finally {
      /* empty */
    }
  };

  const generateAssignmentHandler = async () => {
    const formData = new FormData();
    if (!Object.keys(uploadedFiles).length) {
      Utils.failureToastMessage(
        failureMessageConstant.UPLOAD_MINIUM_ONE_DOCUMENT
      );
      return;
    }
    Object.keys(uploadedFiles).forEach((index) => {
      uploadedFiles[index].forEach((file) => {
        if (Number(index) === 0) {
          formData.append("files", file);
        }
      });
    });
    try {
      setLoadingUploadDocuments(true);
      const assessmentID = JSON.parse(localStorage.getItem(ASSISMENT_DETAIL));
      const [err, uploadDocumentResponse] = await Utils.parseResponse(
        new ClaimManagerService().uploadDocuments(formData, assessmentID?._id)
      );

      if (err) {
        setLoadingUploadDocuments(false);
        Utils.failureToastMessage(
          failureMessageConstant.ERROR_WHILE_UPLOADING_DOCUMENT
        );
      } else {
        await injuryComensationHandler();
        setLoadingUploadDocuments(false);
        setInjuryStep(4);
        navigate(`${window.location.pathname.replace(/\/\d+$/, "")}/4`);
        Utils.successToastMessage(
          successMessageConstant.UPLOADING_DOCUMENT_SUCCESSFULLY
        );

        return uploadDocumentResponse;
      }
    } catch (error) {
      setLoadingUploadDocuments(false);
      Utils.failureToastMessage(
        failureMessageConstant.ERROR_WHILE_UPLOADING_DOCUMENT
      );
    } finally {
      setLoadingUploadDocuments(false);
      /* empty */
    }
  };
  const injurySurveyHandler = async () => {
    const assessmentID = JSON.parse(localStorage.getItem(ASSISMENT_DETAIL));
    const transformedResponse = surveyValue.map((item) => ({
      questionID: item.id,
      answer: item.answer.value
    }));
    const requestData = {
      assessmentID: assessmentID?._id,
      questions: transformedResponse
    };
    try {
      setLoadingInjurySurvey(true);
      const [err, injurySurveyQuestionResponse] = await Utils.parseResponse(
        new ClaimManagerService().addInjurySurveyQuestion(requestData)
      );
      console.log("err", injurySurveyQuestionResponse, err);

      if (err) {
        setLoadingInjurySurvey(false);
        Utils.failureToastMessage(
          failureMessageConstant.ERROR_WHILE_SAVING_INJURY_SURVEY_QUESTION
        );
      } else {
        Utils.localStorageSetItem(SURVEY_QUESTIONS, surveyValue);
        setUploadDocumentPage(1);
        setLoadingInjurySurvey(false);
        setInjuryStep(3);
        navigate(`${window.location.pathname.replace(/\/\d+$/, "")}/3`);
        return injurySurveyQuestionResponse;
      }
    } catch (error) {
      setLoadingInjurySurvey(false);
      Utils.failureToastMessage(
        failureMessageConstant.ERROR_WHILE_SAVING_INJURY_SURVEY_QUESTION
      );
    } finally {
      /* empty */
    }
  };
  const surveyHandler = async () => {
    if (surveyValue.length < surveyQuestions.length) {
      Utils.failureToastMessage(
        failureMessageConstant.PLEASE_ANSWER_ALL_QUESTION
      );
      return;
    }
    await injurySurveyHandler();
  };

  return (
    <div className=" flex w-full flex-col items-center mt-28">
      <div
        // eslint-disable-next-line tailwindcss/no-custom-classname
        className={`${injuryStep === 1 ? "h-135 pb-9" : injuryStep === 2 ? "h-699px pb-9" : injuryStep === 3 && uploadDocumentPage === 1 ? "h-[572px] pb-9" : injuryStep === 3 && uploadDocumentPage === 2 ? "h-[675px] pb-9" : "h-[451px] pb-7"} w-full max-w-152 rounded-4.5 bg-white pl-7 pr-[31px] pt-8 shadow-modal`}
      >
        <div
          className={`flex h-full flex-col ${injuryStep === 1 && " justify-between"}`}
        >
          <div className="flex justify-between">
            <div className="flex w-full gap-1.5 font-Inter text-ft24-32 font-semibold">
              {injuryStep !== 4 && (
                <img
                  onClick={handleBackArrow}
                  src="/images/left-arrow.svg"
                  alt="arrow"
                  className="mr-1 cursor-pointer w-2.5"
                />
              )}
              {injuryStep === 1
                ? "Incident Type"
                : injuryStep === 2
                  ? "Injury Survey"
                  : injuryStep === 3
                    ? "Upload Documents"
                    : "Assessment Report"}
              {injuryStep != 4 && (
                <div className="flex items-center">
                  <Tooltip
                    title={
                      <span className=" font-Inter font-normal text-ft16-19.36 text-black-15 w-121 flex flex-col gap-1 my-2.5 px-2">
                        {injuryStep === 1 ? (
                          <div>{tooltipContent[0]}</div>
                        ) : injuryStep === 2 ? (
                          <div>{tooltipContent[1]}</div>
                        ) : (
                          <>
                            <div>{tooltipContent[2]}</div>
                            <div>{tooltipContent[3]}</div>
                          </>
                        )}
                      </span>
                    }
                    arrow
                    classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
                    placement="right-start"
                    style={{ maxWidth: "484px" }}
                    // leaveDelay={100000}
                  >
                    <Button className="submit-detail-tooltip">
                      <img
                        src="/images/help-icon.svg"
                        alt="info"
                        className="size-4.5 max-w-4.5"
                      />
                    </Button>
                  </Tooltip>
                </div>
              )}
            </div>
            {(injuryStep === 1 || injuryStep === 2) && (
              <div className="flex gap-4 font-Inter text-ft15-17 font-normal">
                <div className={injuryStep === 1 ? "" : "text-grey-BDBDBD"}>
                  1
                </div>
                <div className={injuryStep === 2 ? "" : "text-grey-BDBDBD"}>
                  2
                </div>
              </div>
            )}
            {injuryStep === 3 && uploadDocumentPage === 2 && (
              <div
                onClick={() => setUploadDocumentPage(1)}
                className=" font-Inter font-medium text-ft15-17 text-blue-146EF6 cursor-pointer w-93px min-w-93px flex items-center"
              >
                Upload More
              </div>
            )}
          </div>
          {injuryStep === 1 && (
            <div>
              {loading ? (
                <div className=" w-full flex justify-center items-center">
                  <BiLoaderAlt className="size-10 animate-spin text-blue-146EF6 h-285px" />
                </div>
              ) : (
                <>
                  <p className="pb-7 font-Inter text-ft15-17 font-normal">
                    What type caused your incident?
                  </p>

                  <div className="flex justify-between">
                    <img
                      src={
                        activeImage === "vehicle-accident"
                          ? "/images/vehicle-accident.png"
                          : "/images/vehicle-accident-active.png"
                      }
                      alt="vehicle-accident"
                      onMouseEnter={() => setActiveImage("vehicle-accident")}
                      onMouseLeave={() => setActiveImage("")}
                      className="cursor-pointer"
                      onClick={() => {
                        incidentTypeHandler("vehicle-accident");
                      }}
                    />
                    <img
                      src={
                        activeImage === "fall-down"
                          ? "/images/fall-down.png"
                          : "/images/fall-down-active.png"
                      }
                      alt="fall-down"
                      onMouseEnter={() => setActiveImage("fall-down")}
                      onMouseLeave={() => setActiveImage("")}
                      className="cursor-pointer"
                      onClick={() => {
                        incidentTypeHandler("fall-down");
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          )}
          {injuryStep === 2 && (
            <>
              <div className="mt-10.5 h-[70%] overflow-auto pr-2.5">
                {surveyQuestions?.map((item, index) => (
                  <div key={index} className="mb-4">
                    {index > 0 && <hr className="mb-4 text-grey-E5E5E5" />}
                    <p className="pb-4 font-Inter text-ft15-17 font-normal">
                      {item.questionText}
                    </p>
                    <SurveyOptions
                      data={item.options}
                      question={item?.questionText}
                      questionId={item?._id}
                      value={surveyValue.find(
                        (sv) => sv.question === item?.questionText
                      )}
                      onChange={handleSurveyChange}
                    />
                  </div>
                ))}
              </div>
              <button
                onClick={() => {
                  surveyHandler();
                }}
                className="mt-12 h-53px w-full rounded-lg bg-blue-146EF6 font-Inter text-ft18-21 font-medium text-white"
              >
                {loadingInjurySurvey ? (
                  <div className=" flex justify-center">
                    {" "}
                    <BiLoaderAlt className="size-10 animate-spin text-white" />
                  </div>
                ) : (
                  "Continue"
                )}
              </button>
            </>
          )}
          {injuryStep === 3 && (
            <>
              <div className="mt-[41px]">
                <UploadDocuments
                  uploadedFiles={uploadedFiles}
                  setUploadedFiles={setUploadedFiles}
                  uploadDocumentPage={uploadDocumentPage}
                  setUploadDocumentPage={setUploadDocumentPage}
                />
              </div>
              {uploadDocumentPage === 2 && (
                <button
                  onClick={generateAssignmentHandler}
                  className="mt-9 h-53px w-full rounded-lg bg-blue-146EF6 font-Inter text-ft18-21 font-medium text-white"
                  disabled={loadingUploadDocuments}
                >
                  {loadingUploadDocuments ? (
                    <div className=" flex justify-center">
                      {" "}
                      <BiLoaderAlt className="size-10 animate-spin text-white" />
                    </div>
                  ) : (
                    "Generate Assessment"
                  )}
                </button>
              )}
              <p className=" mt-4 text-center font-Inter text-ft14-20 font-normal text-grey-15">
                All information provided for assessment is true to the best of
                my knowledge
              </p>
            </>
          )}
          {injuryStep === 4 && (
            <>
              <div className="mt-4.5">
                <EmailPopupComponent />
              </div>
              <button
                className="mb-2.5 mt-11 h-53px w-138 rounded-lg bg-blue-146EF6 font-Inter text-ft18-21 font-medium text-white"
                onClick={() => {
                  navigate("/report");
                }}
              >
                Finish
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default InjurySurveyComponent;
